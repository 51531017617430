import { useEffect, useRef, useState } from 'react';
import { queryFocusableElements } from 'lib/domQueries';

/**
 * Creates event handlers for a11y
 * @param {boolean} isOpen is the accordion open
 * @param {React.MutableRefObject<HTMLElement>} ref element containing accordion children
 * @param {() => void} toggle toggle accordion open/close state
 * @returns {{
* button: {
*  onClick: () => void,
*  onKeyDown: (e: React.KeyboardEvent) => void
* },
* content: {
*  onKeyDown: (e: React.KeyboardEvent) => void
* }
* }}
*/
export function useAccordionEventHandlers(isOpen, ref, toggle) {
  const focusableElements = useRef([]);
  const [lastInteraction, setLastInteraction] = useState(null);

  useEffect(() => {
    if (!ref?.current || !isOpen) {
      return;
    }

    focusableElements.current = queryFocusableElements(ref.current);

    if (lastInteraction === 'keydown') {
      focusableElements.current[0]?.focus();
    }
  }, [isOpen]);

  /**
  * Creates a keyboard event handler.
  * @param {'open' | 'closed'} registerOn state when the handler should be registered
  * @param {string[]} supportedKeys keys to handle
  * @param {(context: { up: boolean, down: boolean, tab: boolean, space: boolean, escape: boolean }) => void} fn
  * @returns {(e: React.KeyboardEvent) => void} event handler
  */
  function makeKeyHandler(registerOn, supportedKeys, fn) {
    return function handle(e) {
      const shouldRegister = registerOn === 'open' ? isOpen : !isOpen;

      if (!shouldRegister || !supportedKeys.includes(e.key)) {
        return;
      }

      e.preventDefault();
      setLastInteraction('keydown');

      fn({
        up: e.key === 'ArrowUp',
        down: e.key === 'ArrowDown',
        tab: e.key === 'Tab',
        space: e.key === ' ',
        escape: e.key === 'Escape',
      });
    };
  }

  /**
  * Focuses an element outside of the accordion.
  * @param {number} index index of the accordion item used as the relative position from which to move
  * @param {number} direction number of positions to move
  */
  function focusOutsideElement(index, direction) {
    const allFocusableElements = queryFocusableElements(document);
    const accordionItem = focusableElements.current[index];
    const accordionItemIndex = allFocusableElements.indexOf(accordionItem);
    const nextElement = allFocusableElements[accordionItemIndex + direction];
    nextElement?.focus();
  }

  const buttonOnKeyDown = makeKeyHandler('closed', ['ArrowDown'], ({ down }) => {
    if (down) {
      toggle();
    }
  });

  const contentOnKeyDown = makeKeyHandler('open', ['ArrowDown', 'ArrowUp', 'Tab', ' ', 'Escape'], ({
    up,
    down,
    tab,
    space,
    escape,
  }) => {
    const elements = focusableElements.current;
    const currentIndex = elements.indexOf(document.activeElement);

    if (up || down) {
      const direction = down ? 1 : -1;
      const firstOrLast = down ? 0 : elements.length - 1;
      const nextElement = elements[currentIndex + direction] ?? elements[firstOrLast];

      nextElement?.focus();
    }

    if (space) {
      elements[currentIndex]?.click();
    }

    if (tab) {
      toggle();
      // focus next element after accordion
      focusOutsideElement(elements.length - 1, 1);
    }

    if (escape) {
      toggle();
      // move focus back out of the accordion children
      focusOutsideElement(0, -1);
    }
  });

  /**
   * @returns {void}
   */
  const buttonOnClick = () => {
    toggle();
    setLastInteraction('click');
  };

  return {
    button: {
      onClick: buttonOnClick,
      onKeyDown: buttonOnKeyDown,
    },
    content: {
      onKeyDown: contentOnKeyDown,
    },
  };
}
